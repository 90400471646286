// import React from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   Typography,
// } from "@material-tailwind/react";

// const OurProcess = () => {
//   const steps = [
//     {
//       title: "Ingredient Sourcing",
//       description:
//         "We begin by sourcing the highest quality herbs and natural ingredients from trusted farms and suppliers. Each ingredient is carefully chosen based on its purity, potency, and alignment with Ayurvedic principles.",
//     },
//     {
//       title: "Traditional Formulations",
//       description:
//         "Our formulations are rooted in the timeless wisdom of Ayurveda. We collaborate with experienced Ayurvedic practitioners to create recipes that balance the body, mind, and spirit. These traditional methods are combined with modern techniques to enhance effectiveness.",
//     },
//     {
//       title: "Sustainable Practices",
//       description:
//         "We prioritize sustainability at every step. From eco-friendly farming to using recyclable packaging, we ensure our process respects both nature and community well-being.",
//     },
//     {
//       title: "Quality Control",
//       description:
//         "Every batch of products undergoes rigorous testing to meet high standards of quality and safety. We ensure that our products are free from harmful chemicals, synthetic additives, and artificial preservatives.",
//     },
//     {
//       title: "Expert Craftsmanship",
//       description:
//         "Our team of skilled artisans follows precise procedures to create each product. This includes grinding herbs, blending oils, and preparing decoctions while adhering to traditional Ayurvedic practices.",
//     },
//     {
//       title: "Small-Batch Production",
//       description:
//         "To maintain the freshness and efficacy of our products, we produce them in small batches. This approach allows us to ensure consistency and attention to detail in every unit.",
//     },
//     {
//       title: "Packaging with Care",
//       description:
//         "Our products are packed in hygienic, eco-conscious materials that preserve their natural goodness. We include detailed usage instructions to help you get the best results.",
//     },
//     {
//       title: "Commitment to Wellness",
//       description:
//         "We believe in creating products that truly benefit your health. Every item we craft is designed to promote balance, vitality, and overall well-being in accordance with Ayurvedic principles.",
//     },
//   ];

//   return (
//     <div className="flex flex-col items-center py-10 px-4 md:px-10 bg-gray-50">
//       <Typography
//         variant="h2"
//         className="text-center mb-8 font-bold text-gray-800"
//       >
//         Our Process
//       </Typography>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
//         {steps.map((step, index) => (
//           <Card key={index} className="shadow-md">
//             <CardHeader
//               color="green"
//               className="mb-4 grid h-28 place-items-center bg-green-500"
//             >
//               <Typography variant="h5" color="white" className="text-center">
//                 {step.title}
//               </Typography>
//             </CardHeader>
//             <CardBody>
//               <Typography className="text-gray-700 text-sm">
//                 {step.description}
//               </Typography>
//             </CardBody>
//           </Card>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default OurProcess;

import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

const OurProcess = () => {
  const steps = [
    {
      title: "Ingredient Sourcing",
      description:
        "We begin by sourcing the highest quality herbs and natural ingredients from trusted farms and suppliers. Each ingredient is carefully chosen based on its purity, potency, and alignment with Ayurvedic principles.",
    },
    {
      title: "Traditional Formulations",
      description:
        "Our formulations are rooted in the timeless wisdom of Ayurveda. We collaborate with experienced Ayurvedic practitioners to create recipes that balance the body, mind, and spirit.",
    },
    {
      title: "Sustainable Practices",
      description:
        "We prioritize sustainability at every step. From eco-friendly farming to using recyclable packaging, we ensure our process respects both nature and community well-being.",
    },
    {
      title: "Quality Control",
      description:
        "Every batch of products undergoes rigorous testing to meet high standards of quality and safety. We ensure that our products are free from harmful chemicals, synthetic additives, and artificial preservatives.",
    },
    {
      title: "Expert Craftsmanship",
      description:
        "Our team of skilled artisans follows precise procedures to create each product. This includes grinding herbs, blending oils, and preparing decoctions while adhering to traditional Ayurvedic practices.",
    },
    {
      title: "Small-Batch Production",
      description:
        "To maintain the freshness and efficacy of our products, we produce them in small batches. This approach allows us to ensure consistency and attention to detail in every unit.",
    },
    {
      title: "Packaging with Care",
      description:
        "Our products are packed in hygienic, eco-conscious materials that preserve their natural goodness. We include detailed usage instructions to help you get the best results.",
    },
    {
      title: "Commitment to Wellness",
      description:
        "We believe in creating products that truly benefit your health. Every item we craft is designed to promote balance, vitality, and overall well-being in accordance with Ayurvedic principles.",
    },
    {
      title: "Customer Feedback",
      description:
        "We value our customers' feedback and continuously strive to improve our products based on their experiences and suggestions. This helps us ensure that we meet the highest standards of customer satisfaction and product quality.",
    },
  ];

  return (
    <div className="flex flex-col items-center py-10 px-4 md:px-10 bg-gray-50">
      <Typography
        variant="h2"
        className="text-center mb-8 font-bold text-gray-800"
      >
        Our Process
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {steps.map((step, index) => (
          <Card
            key={index}
            className="shadow-md p-4 m-4 transform transition-transform duration-300 hover:scale-105"
          >
            <CardHeader
              color="green"
              className="mb-4 grid h-28 place-items-center bg-green-500"
            >
              <Typography variant="h5" color="white" className="text-center">
                {step.title}
              </Typography>
            </CardHeader>
            <CardBody>
              <Typography className="text-gray-700 text-base">
                {step.description}
              </Typography>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default OurProcess;
