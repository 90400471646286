import * as React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

export default function FAQs() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="container mx-auto pt-16">
      <Card>
        <CardHeader color="green" className="mb-4">
          <Typography variant="h5" color="white" className="text-center py-2">
            Frequently Asked Questions
          </Typography>
        </CardHeader>
        <CardBody>
          {/* Return Policy */}
          <Accordion open={open === 1}>
            <AccordionHeader onClick={() => handleOpen(1)}>
              What is your return policy?
            </AccordionHeader>
            <AccordionBody>
              Our return policy lasts 30 days. If 30 days have passed since your
              purchase, unfortunately, we can’t offer a refund or exchange.
            </AccordionBody>
          </Accordion>

          {/* Order Tracking */}
          <Accordion open={open === 2}>
            <AccordionHeader onClick={() => handleOpen(2)}>
              How do I track my order?
            </AccordionHeader>
            <AccordionBody>
              You can track your order using the tracking number provided in
              your order confirmation email.
            </AccordionBody>
          </Accordion>

          {/* Ayurvedic Product Ingredients & Authenticity */}
          <Accordion open={open === 4}>
            <AccordionHeader onClick={() => handleOpen(4)}>
              How do you ensure the authenticity and quality of products?
            </AccordionHeader>
            <AccordionBody>
              We source our Ayurvedic products from trusted suppliers with a
              focus on authenticity and quality. Each product undergoes
              stringent quality checks to ensure they meet Ayurvedic standards.
            </AccordionBody>
          </Accordion>

          {/* Shipping & Delivery Times */}
          <Accordion open={open === 5}>
            <AccordionHeader onClick={() => handleOpen(5)}>
              What are the shipping and delivery times for products?
            </AccordionHeader>
            <AccordionBody>
              Delivery times vary based on your location. Typically, orders are
              delivered within 5-7 business days in India and 7-15 business days
              internationally.
            </AccordionBody>
          </Accordion>

          {/* Payment Methods */}
          <Accordion open={open === 6}>
            <AccordionHeader onClick={() => handleOpen(6)}>
              What payment methods do you accept?
            </AccordionHeader>
            <AccordionBody>
              We accept a variety of payment methods, including credit/debit
              cards, UPI, Net Banking, and cash on delivery (COD) for select
              locations.
            </AccordionBody>
          </Accordion>

          {/* Ayurvedic Product Usage & Benefits */}
          <Accordion open={open === 7}>
            <AccordionHeader onClick={() => handleOpen(7)}>
              How do I use Ayurvedic products and what are their benefits?
            </AccordionHeader>
            <AccordionBody>
              Each product comes with detailed usage instructions and benefits.
              Ayurvedic products often help promote wellness, balance, and
              overall health, addressing specific concerns like skin care,
              digestion, and immunity.
            </AccordionBody>
          </Accordion>

          {/* Contact & Customer Support */}
          <Accordion open={open === 9}>
            <AccordionHeader onClick={() => handleOpen(9)}>
              How can I contact customer support?
            </AccordionHeader>
            <AccordionBody>
              You can reach our customer support team via email at
              support@upchar.com or through the contact form on our website. We
              are here to assist you 24/7.
            </AccordionBody>
          </Accordion>
        </CardBody>
      </Card>
    </div>
  );
}
