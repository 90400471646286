// import { Typography } from "@material-tailwind/react";
// import {
//   BsFacebook,
//   BsInstagram,
//   BsTwitter,
//   BsYoutube,
//   BsPinterest,
// } from "react-icons/bs";
// import Newsletter from "./Newsletter";
// import appstoreBtn from "../../assets/images/appstore-btn.svg";
// import googleplayBtn from "../../assets/images/googleplay-btn.svg";
// import amazonpay from "../../assets/images/amazonpay.svg";
// import mastercard from "../../assets/images/mastercard.svg";
// import paypal from "../../assets/images/paypal.svg";
// import visa from "../../assets/images/visa.svg";
// import { Link } from "react-router-dom";

// const LINKS = [
//   {
//     title: "Get to know us",
//     items: [
//       { name: "Privacy Policy", route: "/policy" },
//       { name: "Blog", route: "/blog" },
//       { name: "News", route: "/news" },
//       { name: "Our value", route: "/values" },
//     ],
//   },
//   {
//     title: "For Consumers",
//     items: [
//       { name: "FAQs", route: "/faq" },
//       { name: "Return/Exchange", route: "/exchange" },
//       { name: "Special Offers", route: "/specialOffers" },
//       { name: "Support", route: "/support" },
//     ],
//   },
//   {
//     title: "Get deliveries with Us",
//     items: [
//       { name: "App Store", route: "#", imgSrc: appstoreBtn },
//       { name: "Google Play", route: "#", imgSrc: googleplayBtn },
//     ],
//   },
// ];

// const currentYear = new Date().getFullYear();

// function Footer() {
//   return (
//     <footer className="relative w-full mt-10 mb-10">
//       <div className="mx-auto w-full max-w-7xl px-8">
//         <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-3">
//           <Typography variant="h5" className="mb-6">
//             <Newsletter />
//           </Typography>
//           <div className="grid grid-cols-3 justify-between gap-4 mt-10">
//             {LINKS.map(({ title, items }) => (
//               <ul key={title}>
//                 <Typography
//                   variant="medium"
//                   color="black"
//                   className="mb-3 font-medium text-xl"
//                 >
//                   {title}
//                 </Typography>
//                 {items.map(({ name, route }) => (
//                   <li key={name}>
//                     <Typography
//                       as={Link}
//                       to={route}
//                       href="#"
//                       color="gray"
//                       className="py-1.5 font-normal transition-colors hover:text-blue-gray-900"
//                     >
//                       {name}
//                     </Typography>
//                   </li>
//                 ))}
//               </ul>
//             ))}
//           </div>
//           <div className="">
//             <ul className="gap-3 list-inline mb-0 text-center lg:mt-0 md:mt-5 lg:text-lg-end">
//               <li className="list-inline-item ms-4">
//                 <a href="#!">
//                   <img src={appstoreBtn} alt="" style={{ width: "140px" }} />
//                 </a>
//               </li>
//               <li className="list-inline-item">
//                 <a href="#!">
//                   <img src={googleplayBtn} alt="" style={{ width: "140px" }} />
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>

//         <div className="pt-10 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
//           <Typography
//             variant="small"
//             className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
//           >
//             &copy; {currentYear}{" "}
//             <a href="/">Upchar Herbal & Ayurveda Pharmaceuticals</a>. All Rights
//             Reserved.
//           </Typography>
//           <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
//             <Typography
//               as="a"
//               href="#"
//               className="opacity-80 transition-opacity hover:opacity-100 text-xl"
//             >
//               <BsFacebook />
//             </Typography>
//             <Typography
//               as="a"
//               href="#"
//               className="opacity-80 transition-opacity hover:opacity-100 text-xl"
//             >
//               <BsInstagram />
//             </Typography>
//             <Typography
//               as="a"
//               href="#"
//               className="opacity-80 transition-opacity hover:opacity-100 text-xl"
//             >
//               <BsTwitter />
//             </Typography>
//             <Typography
//               as="a"
//               href="#"
//               className="opacity-80 transition-opacity hover:opacity-100 text-xl"
//             >
//               <BsYoutube />
//             </Typography>
//             <Typography
//               as="a"
//               href="#"
//               className="opacity-80 transition-opacity hover:opacity-100 text-xl"
//             >
//               <BsPinterest />
//             </Typography>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;

import { Typography } from "@material-tailwind/react";
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsYoutube,
  BsPinterest,
} from "react-icons/bs";
import Newsletter from "./Newsletter";
import appstoreBtn from "../../assets/images/appstore-btn.svg";
import googleplayBtn from "../../assets/images/googleplay-btn.svg";
import amazonpay from "../../assets/images/amazonpay.svg";
import mastercard from "../../assets/images/mastercard.svg";
import paypal from "../../assets/images/paypal.svg";
import visa from "../../assets/images/visa.svg";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/footer-logo.png";

const LINKS = [
  {
    title: "Get to know us",
    items: [
      { name: "Privacy Policy", route: "/privacy-policy" },
      { name: "Blog", route: "/blog" },
      { name: "News", route: "/news" },
    ],
  },
  {
    title: "For Consumers",
    items: [
      { name: "FAQs", route: "/faq" },
      { name: "Return/Exchange", route: "/exchange" },
      { name: "Support", route: "/support" },
    ],
  },
  {
    title: "Also available on",
    items: [
      { name: "App Store", route: "#", imgSrc: appstoreBtn },
      { name: "Google Play", route: "#", imgSrc: googleplayBtn },
    ],
  },
];

const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <footer className="relative w-full mt-10 mb-10">
      <div className="mx-auto w-full max-w-8xl px-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2 flex items-center">
            <img
              src={footerLogo}
              alt="footer-logo"
              style={{ width: "180px", height: "auto" }}
            />
          </div>
          <div className="col-span-5">
            <Typography variant="h5" className="mb-6">
              <Newsletter />
            </Typography>
          </div>
          <div className="col-span-5 grid grid-cols-3 gap-4 mt-10">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  variant="medium"
                  color="black"
                  className="mb-3 font-medium text-xl"
                >
                  {title}
                </Typography>
                {items.map(({ name, route, imgSrc }) => (
                  <li key={name}>
                    {imgSrc ? (
                      <a href={route}>
                        <img
                          src={imgSrc}
                          alt={name}
                          style={{ width: "140px" }}
                          className="mb-4 pt-4"
                        />
                      </a>
                    ) : (
                      <Typography
                        as={Link}
                        to={route}
                        href="#"
                        color="gray"
                        className="py-1.5 font-normal transition-colors hover:text-blue-gray-900"
                      >
                        {name}
                      </Typography>
                    )}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>

        <div className="pt-10 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
          >
            &copy; {currentYear}{" "}
            <a href="/">Upchar Herbal & Ayurveda Pharmaceuticals</a>. All Rights
            Reserved.
          </Typography>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100 text-xl"
            >
              <BsFacebook />
            </Typography>
            <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100 text-xl"
            >
              <BsInstagram />
            </Typography>
            <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100 text-xl"
            >
              <BsTwitter />
            </Typography>
            <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100 text-xl"
            >
              <BsYoutube />
            </Typography>
            <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100 text-xl"
            >
              <BsPinterest />
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
