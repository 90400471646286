import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import {
  selectBillAddress,
  selectOrderDetails,
  selectProductDataDetails,
  selectSums,
} from "../OrderDetails/orderDetailsSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SideBarNav from "../UserProfile/SideBarNav";
import InvoicePDF from "./InvoicePDF";

export default function Invoice() {
  const orderDetail = useSelector(selectOrderDetails);
  const productDataDetail = useSelector(selectProductDataDetails);
  const billingAddress = useSelector(selectBillAddress);
  const sums = useSelector(selectSums);
  console.log("orderDetail", sums);

  if (
    !orderDetail.length ||
    !productDataDetail.length ||
    !billingAddress.length
  ) {
    return (
      <div className="flex items-center justify-center pt-24 overflow-hidden">
        <div className="text-center">
          <Typography variant="h5" color="red" className="mb-4">
            No invoice data available
          </Typography>
          <Typography variant="body1" color="gray">
            Please ensure all required data is available or contact support.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex mx-auto">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <Card className="w-2/3 px-5 ml-64">
        <CardBody>
          <div className="flex justify-between">
            <Typography variant="h4" color="blue-gray" className="mb-4">
              Invoice
            </Typography>
            <PDFDownloadLink
              document={
                <InvoicePDF
                  orderDetail={orderDetail}
                  productDataDetail={productDataDetail}
                  billingAddress={billingAddress}
                  sums={sums}
                />
              }
              fileName="invoice.pdf"
            >
              {({ loading }) =>
                loading ? (
                  <Button variant="text" color="blue">
                    Loading document...
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    className="bg-green-400 text-white hover:bg-green-500 hover:shadow-lg"
                  >
                    Print Invoice
                  </Button>
                )
              }
            </PDFDownloadLink>
          </div>

          <div className="mb-5 flex justify-between">
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-2">
                Order Summary
              </Typography>
              <Typography variant="body1" color="gray">
                Tracking number: {orderDetail[0].trackingId}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-2">
                Payment Information
              </Typography>
              {orderDetail[0].paymentMethod === "cash" ? (
                <Typography variant="body1" color="blue-gray">
                  Cash on delivery
                </Typography>
              ) : (
                <div>
                  <Typography variant="body1" color="blue-gray">
                    Ending with 4242
                  </Typography>
                  <Typography variant="body1" color="blue-gray">
                    Expires 12 / 21
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className="mb-6">
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Products
            </Typography>
            {productDataDetail?.map((product) => (
              <div key={product.id} className="flex justify-between mb-4">
                <div className="flex items-center">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-16 w-16 object-cover rounded-md mr-4"
                  />
                  <div>
                    <Typography variant="body1" color="blue-gray">
                      {product.name}
                    </Typography>
                    <Typography variant="small" color="gray">
                      {product.type}
                    </Typography>
                  </div>
                </div>
                <Typography variant="body1" color="blue-gray">
                  ₹ {product.price * product.quantity}
                </Typography>
              </div>
            ))}
          </div>

          <div className="mb-6">
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Billing Address
            </Typography>
            <Typography variant="body1" color="blue-gray">
              {billingAddress[0].fname} {billingAddress[0].lname}
            </Typography>
            <Typography variant="body1" color="blue-gray">
              {orderDetail[0].billingAddress}
            </Typography>
          </div>

          <div className="mb-6">
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Summary
            </Typography>
            <div className="flex justify-between">
              <Typography variant="body1" color="blue-gray">
                Subtotal
              </Typography>
              <Typography variant="body1" color="blue-gray">
                ₹ {sums.totalSubtotal}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography variant="body1" color="blue-gray">
                Shipping
              </Typography>
              <Typography variant="body1" color="blue-gray">
                ₹ {sums.totalShippingCost}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography variant="body1" color="blue-gray">
                Taxes
              </Typography>
              <Typography variant="body1" color="blue-gray">
                ₹ {sums.totalTax}
              </Typography>
            </div>
            <div className="flex justify-between font-bold mt-4">
              <Typography variant="h6" color="blue-gray">
                Total
              </Typography>
              <Typography variant="h6" color="blue-gray">
                ₹ {sums.totalTotal}
              </Typography>
            </div>
          </div>
          <div className="flex justify-between">
            <div></div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
