import React from "react";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

// const features = [
//   {
//     icon: <LocalShippingIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
//     title: "Free Delivery",
//     description: `
//       Enjoy the freedom of shopping with us! Get your orders delivered
//       straight to your doorstep, absolutely free on all orders over $50.
//       Shop more, save more, and let us bring convenience to you!
//     `,
//   },
//   {
//     icon: <AttachMoneyIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
//     title: "Money Returns",
//     description: `
//       Your satisfaction is our promise. With our 30-day money-back guarantee,
//       you can shop confidently, knowing that your happiness is always a priority.
//       Hassle-free returns, no questions asked!
//     `,
//   },
//   {
//     icon: <SupportAgentIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
//     title: "24/7 Support",
//     description: `
//       We're always here, just a call or click away. Our dedicated support team
//       is available around the clock, ready to assist you with any questions or
//       concerns. Day or night, we’ve got your back!
//     `,
//   },
// ];

const features = [
  {
    icon: <LocalShippingIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
    title: "Free Delivery",
    subtitle: "Enjoy the freedom of shopping with us!",
    description: `
       Get your orders delivered 
      straight to your doorstep, absolutely free On orders over ₹ 500. Shop more, save more!
    `,
  },
  {
    icon: <CurrencyRupeeIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
    title: "Money Returns",
    subtitle: "30-day money-back guarantee",
    description: `
      Your satisfaction is our promise. Shop confidently, knowing that 
      your happiness is always a priority. Hassle-free returns, no 
      questions asked!
    `,
  },
  {
    icon: <SupportAgentIcon style={{ fontSize: 40, color: "#3b82f6" }} />,
    title: "24/7 Support",
    subtitle: "Always here for you",
    description: `
      Our dedicated support team is available around the clock, ready 
      to assist you with any questions or concerns. Day or night, we’ve 
      got your back!
    `,
  },
];
const OurFeatures = ({ productRef }) => {
  const scrollToProduct = () => {
    const offset = -100;
    const topPosition =
      productRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top: topPosition, behavior: "smooth" });
  };

  return (
    <div className="container mx-auto my-10 px-4">
      {/* Section Heading */}
      <div className="text-center mb-12">
        <Typography variant="h1" className="text-4xl font-bold text-gray-800">
          Unlock the Magic of Shopping with Us!
        </Typography>
        <Typography variant="lead" className="text-gray-600 mt-2">
          Discover the perks that make us special and experience shopping like
          never before.
        </Typography>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <Card
            key={index}
            className="shadow-lg hover:shadow-2xl transition-shadow duration-300"
          >
            <CardBody className="flex flex-col items-center text-center">
              <div className="mb-4">{feature.icon}</div>
              <Typography variant="h2" className="text-xl font-semibold mb-2">
                {feature.title}
              </Typography>
              <Typography variant="h3" className="text-lg font-medium mb-2">
                {feature.subtitle}
              </Typography>
              <Typography className="text-gray-600">
                {feature.description}
              </Typography>
            </CardBody>
          </Card>
        ))}
      </div>

      {/* Section Call-to-Action */}
      <div className="text-center mt-16">
        <Typography
          variant="h3"
          className="text-2xl font-semibold text-gray-800"
        >
          Experience Shopping with a Difference
        </Typography>
        <Typography className="text-gray-600 mt-2 mb-4">
          Let us take care of your shopping needs—Join the revolution of
          effortless shopping today!
        </Typography>
        <Button color="blue" size="lg" onClick={scrollToProduct}>
          Start Shopping Now
        </Button>
      </div>
    </div>
  );
};

export default OurFeatures;
