// import React from "react";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Typography,
// } from "@material-tailwind/react";

// const PrivacyPolicy = () => {
//   return (
//     <div className="flex flex-col items-center py-10 px-4 md:px-10">
//       <Card className="w-full max-w-4xl shadow-md">
//         <CardHeader color="green" className="mb-4">
//           <Typography variant="h5" color="white" className="text-center py-2">
//             Privacy Policy
//           </Typography>
//         </CardHeader>
//         <CardBody>
//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             1. Introduction
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             Welcome to our Privacy Policy page! When you use our web services,
//             you trust us with your information. This Privacy Policy is meant to
//             help you understand what data we collect, why we collect it, and
//             what we do with it. This is important; we hope you will take time to
//             read it carefully. And remember, you can find controls to manage
//             your information and protect your privacy and security. We’ve tried
//             to keep it as simple as possible.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             2. Information We Collect
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             We collect information to provide better services to all our users –
//             from figuring out basic stuff like which language you speak, to more
//             complex things like which ads you’ll find most useful, the people
//             who matter most to you online, or which YouTube videos you might
//             like. We may collect personal information such as your name, email
//             address, contact details, and payment information when you use our
//             services. Additionally, we may gather non-personal data like device
//             information, browser type, and usage patterns to enhance your
//             experience.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             3. How We Use Information We Collect
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             The information we collect is used to process your orders, provide
//             customer support, personalize your experience, and improve our
//             platform. We may also use your data for marketing purposes with your
//             consent. We use the information we collect from all of our services
//             to provide, maintain, protect and improve them, to develop new ones,
//             and to protect our users. We also use this information to offer you
//             tailored content – like giving you more relevant search results and
//             ads.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             4. Sharing Your Information
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             We do not sell or rent your personal information to third parties.
//             However, we may share your data with trusted service providers,
//             payment processors, or as required by law to ensure seamless service
//             delivery.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             5. Data Security
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             We implement advanced security measures to protect your data from
//             unauthorized access, alteration, or disclosure. Despite our efforts,
//             no method of transmission over the Internet is 100% secure.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             6. Your Rights
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             You have the right to access, update, or delete your personal
//             information. If you wish to exercise these rights, please contact
//             our support team at support@upchar.com.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             7. Transparency and Choice
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             People have different privacy concerns. Our goal is to be clear
//             about what information we collect, so that you can make meaningful
//             choices about how it is used. For example, you can:
//           </Typography>
//           <ul className="list-disc list-inside text-gray-700 text-base mb-6">
//             <li>
//               Review and update your activity controls to decide what types of
//               data you would like saved with your account when you use our
//               services.
//             </li>
//             <li>
//               Review and control certain types of information tied to your
//               account by using our Dashboard.
//             </li>
//             <li>
//               View and edit your preferences about the ads shown to you on our
//               platform and across the web, such as which categories might
//               interest you, using Ads Settings.
//             </li>
//             <li>
//               Adjust how the Profile associated with your account appears to
//               others.
//             </li>
//           </ul>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             8. Changes to This Policy
//           </Typography>
//           <Typography className="text-gray-700 text-base mb-6">
//             We may update this Privacy Policy periodically to reflect changes in
//             our practices or for other operational, legal, or regulatory
//             reasons. Please review this page regularly for updates.
//           </Typography>

//           <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
//             9. Contact Us
//           </Typography>
//           <Typography className="text-gray-700 text-base">
//             If you have any questions or concerns about our Privacy Policy,
//             please reach out to us at support@upchar.com.
//           </Typography>
//         </CardBody>
//       </Card>
//     </div>
//   );
// };

// export default PrivacyPolicy;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center py-10 px-4 md:px-10">
      <Card className="w-full max-w-4xl shadow-md">
        <CardHeader color="green" className="mb-4">
          <Typography variant="h5" color="white" className="text-center py-2">
            Privacy Policy
          </Typography>
        </CardHeader>
        <CardBody>
          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            1. Introduction
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            Welcome to our Privacy Policy page! When you use our web services,
            you trust us with your information. This Privacy Policy is meant to
            help you understand what data we collect, why we collect it, and
            what we do with it. This is important; we hope you will take time to
            read it carefully. And remember, you can find controls to manage
            your information and protect your privacy and security. We’ve tried
            to keep it as simple as possible.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            2. Information We Collect
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            We collect information to provide better services to all our users –
            from figuring out basic stuff like which language you speak, to more
            complex things like which ads you’ll find most useful, the people
            who matter most to you online, or which YouTube videos you might
            like. We may collect personal information such as your name, email
            address, contact details, and payment information when you use our
            services. Additionally, we may gather non-personal data like device
            information, browser type, and usage patterns to enhance your
            experience.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            3. How We Use Information We Collect
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            The information we collect is used to process your orders, provide
            customer support, personalize your experience, and improve our
            platform. We may also use your data for marketing purposes with your
            consent. We use the information we collect from all of our services
            to provide, maintain, protect and improve them, to develop new ones,
            and to protect our users. We also use this information to offer you
            tailored content – like giving you more relevant search results and
            ads.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            4. Sharing Your Information
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            We do not sell or rent your personal information to third parties.
            However, we may share your data with trusted service providers,
            payment processors, or as required by law to ensure seamless service
            delivery.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            5. Data Security
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            We implement advanced security measures to protect your data from
            unauthorized access, alteration, or disclosure. Despite our efforts,
            no method of transmission over the Internet is 100% secure.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            6. Your Rights
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            You have the right to access, update, or delete your personal
            information. If you wish to exercise these rights, please contact
            our support team at support@upchar.com.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            7. Transparency and Choice
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            People have different privacy concerns. Our goal is to be clear
            about what information we collect, so that you can make meaningful
            choices about how it is used. For example, you can:
          </Typography>
          <ul className="list-disc list-inside text-gray-700 text-base mb-6 leading-relaxed">
            <li>
              Review and update your activity controls to decide what types of
              data you would like saved with your account when you use our
              services.
            </li>
            <li>
              Review and control certain types of information tied to your
              account by using our Dashboard.
            </li>
            <li>
              View and edit your preferences about the ads shown to you on our
              platform and across the web, such as which categories might
              interest you, using Ads Settings.
            </li>
            <li>
              Adjust how the Profile associated with your account appears to
              others.
            </li>
          </ul>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            8. Changes to This Policy
          </Typography>
          <Typography className="text-gray-700 text-base mb-6 leading-relaxed">
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for other operational, legal, or regulatory
            reasons. Please review this page regularly for updates.
          </Typography>

          <Typography variant="h5" className="font-semibold mb-4 text-gray-800">
            9. Contact Us
          </Typography>
          <Typography className="text-gray-700 text-base leading-relaxed">
            If you have any questions or concerns about our Privacy Policy,
            please reach out to us at support@upchar.com.
          </Typography>
        </CardBody>
      </Card>
      <footer className="mt-10 text-center text-gray-600">
        <Typography variant="small">
          &copy; {new Date().getFullYear()} Upchar. All rights reserved.
        </Typography>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
