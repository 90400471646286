import React, { useRef, useState } from "react";
// import './FrontPageForm.css';
import { Carousel, Typography, Button } from "@material-tailwind/react";
import slideshow1 from "../../assets/images/slideshow1.png";
import slideshow2 from "../../assets/images/slideshow2.png";
import slideshow3 from "../../assets/images/slideshow3.png";
import { IoArrowRedoCircle } from "react-icons/io5";
import first from "../../assets/images/icons/first.png";
import second from "../../assets/images/icons/second.png";
import third from "../../assets/images/icons/third.png";
import processSection from "../../assets/images/processSection.jpg";
import { motion, useInView } from "framer-motion";

const features = [
  {
    name: "SOURCING PURE HERBS",
    description:
      "We source our herbs from local farms that follow organic practices, ensuring that you receive high-quality Ayurvedic remedies with a personal touch.",
    icon: first,
  },
  {
    name: "TRADITIONAL PREPARATION",
    description:
      "Our products are made using traditional Ayurvedic methods passed down through generations, preserving the natural benefits of the herbs in every product.",
    icon: second,
  },
  {
    name: "QUALITY ASSURANCE",
    description:
      "Each product is thoroughly tested in local facilities to ensure it meets the highest standards of purity, safety, and effectiveness before reaching you.",
    icon: third,
  },
];

function FrontPageForm() {
  const processRef = useRef(null);
  const isInView = useInView(processRef, { once: true });

  return (
    <>
      <Carousel
        loop={true}
        autoplay={true}
        autoplayDelay={5000}
        transition={{ duration: 2 }}
        className="rounded-xl bg-green-500"
      >
        <div className="relative">
          <div className="absolute left-0 top-0 h-full flex">
            <div
              className="text-green-100 pl-20 pt-80 mt-12 max-w-3xl ml-5 flex gap-10"
              style={{ color: "#CEE49E" }}
            >
              <div>
                <p className="text-4xl font-bold mb-4">HERBAL ESSENTIALS</p>
                {/* <p className="text-2xl mb-4">Save Up to 30% Off</p> */}
                <p className="mb-6">
                  Experience the healing power of natural herbs and oils.
                </p>
              </div>
              <div>
                <Button
                  // color="green"
                  // variant="gradient"
                  className="rounded-full flex p-4 items-center"
                  style={{ backgroundColor: "#21290F", color: "#CEE49E" }}
                >
                  <IoArrowRedoCircle className="text-3xl mr-2" />
                  <span className="text-sm">Explore</span>
                </Button>
              </div>
            </div>
          </div>
          <div>
            <img
              src={slideshow2}
              alt="Herbal Essentials slide"
              className="w-full object-cover"
            />
          </div>
        </div>

        <div className="relative">
          <div className="absolute left-0 top-0 h-full flex">
            <div className="text-white p-8 pt-80 mt-12 max-w-3xl ml-5 flex gap-8">
              <div>
                <p className="text-4xl font-bold mb-4">
                  PURE AYURVEDIC PRODUCTS
                </p>
                {/* <p className="text-2xl mb-4">Get up to 30% Off</p> */}
                <p className="mb-6">
                  Natural ingredients with centuries-old Ayurvedic wisdom.
                </p>
              </div>
              <div>
                <Button
                  color="green"
                  variant="gradient"
                  className="rounded-full flex p-3 items-center"
                >
                  <IoArrowRedoCircle className="text-3xl mr-2" />
                  <span className="text-sm">Shop Now</span>
                </Button>
              </div>
            </div>
          </div>
          <div>
            <img
              src={slideshow1}
              alt="Ayurvedic product slide"
              className="w-full object-cover"
            />
          </div>
        </div>

        <div className="relative">
          <div className="absolute left-0 top-0 h-full flex">
            <div className="text-white pt-96 pl-24 mt-10 max-w-3xl ml-5 flex gap-5">
              <div>
                <p className="text-4xl font-bold mb-4">NATURAL WELLNESS</p>
                {/* <p className="text-2xl mb-4">Discover Ayurveda</p> */}
                <p className="mb-6">
                  Holistic wellness with 100% natural Ayurvedic ingredients.
                </p>
              </div>
              <div>
                <Button
                  color="green"
                  variant="gradient"
                  className="rounded-full flex p-4 items-center"
                >
                  <IoArrowRedoCircle className="text-3xl mr-2" />
                  <span className="text-sm">Learn More</span>
                </Button>
              </div>
            </div>
          </div>
          <div>
            <img
              src={slideshow3}
              alt="Natural Wellness slide"
              className="w-full object-cover"
            />
          </div>
        </div>
      </Carousel>

      {/* <div id="process">
        <div className="overflow-hidden bg-white py-5 sm:pt-28">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="flex">
              <h2 className="font-semibold text-green-600 mb-2 translate-y-2">
                Discover the
              </h2>
              <p className="text-3xl font-bold text-gray-900 sm:text-4xl ml-3 underline">
                Ayurveda Process
              </p>
            </div>
            <div className="mt-5 mx-auto grid max-w-2xl grid-cols-1 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none text-justify">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <img
                            src={feature.icon}
                            alt={feature.name}
                            className="absolute left-1 top-1 pr-1 h-7 w-7 text-green-600"
                          />
                          {feature.name}
                        </dt>{" "}
                        <br />
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src={processSection}
                alt="Ayurvedic Process"
                className="mt-3 w-[2rem] max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              />
            </div>
          </div>
        </div>
      </div> */}

      <div id="process" ref={processRef}>
        <div className="overflow-hidden bg-white py-5 sm:pt-28">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <motion.div
              className="flex"
              initial={{ opacity: 0, y: 20 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              <h2 className="font-semibold text-green-600 mb-2 translate-y-2">
                Discover the
              </h2>
              <p className="text-3xl font-bold text-gray-900 sm:text-4xl ml-3 underline">
                Ayurveda Process
              </p>
            </motion.div>
            <div className="mt-5 mx-auto grid max-w-2xl grid-cols-1 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none text-justify">
                    {features.map((feature) => (
                      <motion.div
                        key={feature.name}
                        className="relative pl-9"
                        initial={{ opacity: 0, x: -20 }}
                        animate={isInView ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        <dt className="inline font-semibold text-gray-900">
                          <img
                            src={feature.icon}
                            alt={feature.name}
                            className="absolute left-1 top-1 pr-1 h-7 w-7 text-green-600"
                          />
                          {feature.name}
                        </dt>{" "}
                        <br />
                        <dd className="inline">{feature.description}</dd>
                      </motion.div>
                    ))}
                  </dl>
                </div>
              </div>
              <motion.img
                src={processSection}
                alt="Ayurvedic Process"
                className="mt-3 w-[2rem] max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={isInView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.5, delay: 0.4 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FrontPageForm;
