import React, { useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setLogin } from "../Counter/userDetailsSlice";
import { selectCartProduct, setAddToCart } from "../Cart/cartProductSlice";
import ProductToCart from "../Cart/ProductToCart";
import { Button, Card, Typography } from "@material-tailwind/react";

function EmailVerify() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  const userDetail = useSelector(selectUserDetails);
  const cartItems = useSelector(selectCartProduct);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input box if the current one is filled
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 4);
    const newOtp = [...otp];
    for (let i = 0; i < pasteData.length; i++) {
      if (/^\d*$/.test(pasteData[i])) {
        newOtp[i] = pasteData[i];
      }
    }
    setOtp(newOtp);
  };

  const verifyOtp = () => {
    const otpValue = otp.join("");
    axios
      .post(`${apiUrl}/auth/verify`, { otp: otpValue })
      .then((res) => {
        if (res.data.success) {
          console.log("User verified.");
          const combinedData = res.data.cartItems.map((cartItem, index) => ({
            cartItem,
            productDetails: res.data.productDetails[index],
          }));
          const productDetailsArray = combinedData.map(
            (item) => item.productDetails
          );
          localStorage.removeItem("tempToken_local");
          localStorage.setItem("token_local", res.data.token);
          localStorage.setItem(
            "cart_product",
            JSON.stringify(productDetailsArray)
          );
          dispatch(setAddToCart(combinedData));
          const loginData = {
            token: res.data.token,
            userDetail: res.data.data,
          };
          dispatch(setLogin(loginData));
          navigate("/userprofile");
        } else {
          console.log("User not verified.");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const resendOtp = () => {
    const userEmail = localStorage.getItem("user");
    console.log("user", userEmail);
    if (userEmail) {
      axios
        .post(`${apiUrl}/auth/resend-otp`, { userEmail: userEmail })
        .then((res) => {
          if (res.data.success) {
            console.log("OTP resent successfully.");
          } else {
            console.log("Failed to resend OTP.");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      console.log("User details are not available.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-100 overflow-hidden">
      <Card className="w-full max-w-md p-6">
        <Typography variant="h4" color="blue-gray" className="mb-6">
          Verify OTP
        </Typography>
        <div className="flex justify-between mb-4" onPaste={handlePaste}>
          {otp.map((value, index) => (
            <div
              key={index}
              tabIndex="0"
              ref={(el) => (inputRefs.current[index] = el)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onInput={(e) => handleInputChange(index, e.target.innerText)}
              onFocus={(e) => {
                const range = document.createRange();
                const sel = window.getSelection();
                range.setStart(e.target, 0);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
              }}
              contentEditable
              className="w-12 h-12 pt-2 border border-gray-300 rounded-md text-center text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              suppressContentEditableWarning={true}
            >
              {value}
            </div>
          ))}
        </div>
        <Link
          onClick={(e) => {
            e.preventDefault();
            resendOtp();
          }}
          className="text-blue-500 hover:underline mb-4 block text-right"
        >
          Resend OTP
        </Link>
        <Button type="submit" color="blue" size="lg" onClick={verifyOtp}>
          Verify OTP
        </Button>
        <ProductToCart userId={userDetail} cartItems={cartItems} />
      </Card>
    </div>
  );
}

export default EmailVerify;
